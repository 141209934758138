import React from "react";

function V2() {
    return (
        <svg
            style={{
                width: '150px',
                height: '150px',
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="1265.88"
            height="98.267"
            viewBox="0 0 1265.88 98.267"
        >
            <path
                fill="#7df3e1"
                d="M21.44 16.325L65.008 95.48l50.448.04 42.824-79.206 21.465.01V1.97H115.18v14.344h18.648l-31.184 57.66-31.604-57.65 22.664-.01-.006-14.344H.02L0 16.325h21.44m555.097-.038l-.009-14.316 86.384.011v14.305h-21.515v64.616h19.948c18.486 0 30.603-12.207 30.603-28.773h14.25v43.267l-129.67.01V80.923h21.777l-.028-64.636h-21.74m-209.95 67.118c-15.84-.287-22.804-17.795-22.5-35.191.314-18.017 8.936-33.727 23.774-33.468 14.839.26 22.674 16.27 22.355 34.279-.301 16.989-7.315 34.677-23.63 34.38m.482-83.406c-43.377 0-66.416 21.64-66.416 48.794 0 27.528 22.184 49.474 66.667 49.474 44.468 0 66.416-21.946 66.416-49.474 0-27.154-23.283-48.794-66.667-48.794m486.488 16.326l43.567 79.155 50.448.04 42.824-79.206 21.465.01V1.97h-64.568v14.344h18.65l-31.183 57.66-31.604-57.65 22.664-.01-.006-14.344h-93.68l-.018 14.355h21.441m345.172 67.08c-15.84-.287-22.803-17.795-22.5-35.191.316-18.017 8.936-33.727 23.775-33.468 14.84.26 22.673 16.27 22.353 34.279-.3 16.989-7.312 34.677-23.628 34.38m.481-83.406c-43.377 0-66.414 21.64-66.414 48.794 0 27.528 22.182 49.474 66.665 49.474 44.47 0 66.417-21.946 66.417-49.474 0-27.154-23.282-48.794-66.668-48.794"
            ></path>
        </svg>
    );
}

export default V2;
